@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "bold";
  src: url("./Asset/font/AdobeDevanagari-Bold\ 2.otf") format("woff2");

  font-weight: bold;
}
@font-face {
  font-family: "regular";
  src: url("./Asset/font/AdobeDevanagari-Regular\ 3.otf") format("woff2");

  font-weight: regular;
}

.font-regular {
  font-family: regular;
}
.font-bold {
  font-family: bold;
}
